<template>
	<span>	
		<v-btn color="primary" small :to="path">{{mwtr.Edit}}</v-btn>
	</span>	
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";

	export default {
		name: "EditButton", 
		props: {
			id: {},
			collection: {}, 
			pathName: {}
		},
		computed: {
			path(){
				var pathName = this.pathName ? this.pathName : this.collection;
				return `/admin/${pathName}/${this.id}`
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"