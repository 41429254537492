<template>
	<div>	
		<v-autocomplete 
			v-if="searchMethod == 'tags'"
			:label="mwtr.SearchByTagsLabel"
			multiple 
			chips 
			v-model="selected" 
			deletable-chips 
			:items="items"
			@click:prepend="toggleSearchMethod"
			prepend-icon="mdi-tag-multiple"
			>
		</v-autocomplete>
		<v-text-field v-else 
			v-model="searchString" 
			:label="mwtr.Search"
			prepend-icon="mdi-magnify"
			@click:prepend="toggleSearchMethod"
		></v-text-field>
		<p v-if="selected.length > 1" class="text-right small">
			{{operator == 'and' ? mwtr.OperatorStringAnd : mwtr.OperatorStringOr}} <v-btn x-small color="primary" @click="toggleOperator">{{mwtr.ChangeButton}}</v-btn>
		</p>
	</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "SearchByTags", 
		props: {
			searchItems: {}
		},
		data: () => {
			return {
				selected: [],
				operator: "or",
				searchMethod: "string", 
				searchString: ""
			}
		},
		// components: {

		// },
		computed: {
			...Vuex.mapState({
				tags: state => state.tags,
			}),
			items(){
				return this.mwutilities.itemsArray(this.tags)
			},
			operatorIcon(){
				return this.operator == "and" ? "mdi-ampersand" : "or"

			}
		},
		methods: {
			toggleSearchMethod(){
				if( this.searchMethod == "tags" ){
					this.searchMethod = "string"
				}
				else if( this.searchMethod == "string" ){
					this.searchMethod = "tags"
				}
			},
			toggleOperator(){
				if( this.operator == "or" ){
					this.operator = "and"
				}
				else if( this.operator == "and" ){
					this.operator = "or"
				}
			},
			test(){
				const self = this;
				var selected = []
				self.selected.forEach( tag => {
					var matched = Object.keys(self.searchItems).filter( id => {
						var item = self.searchItems[id];
						if( !item.tags ){
							return;
						} 
						return item.tags.indexOf( tag ) > -1;
					})
					if( !selected.length ){
						selected = matched;
					}
					else{					
						if( self.operator == "or" ){
							matched.forEach( id => {
								if( selected.indexOf(id) < 0 ){
									matched.push( id )
								}
							})
						}
						if( self.operator == "and" ){
							selected = selected.filter(id => matched.includes(id));
						}
					}
				})
				this.$emit("change", selected);
			}
		},
		watch: {
			searchMethod(){
				this.selected = [];
				this.searchString = "";
			},
			operator(){
				this.test();
			},
			selected(){
				if( this.selected.length == 0 ){
					this.$emit("change", false)
				}
				else{
					this.test();
				}
			},
			searchString(){
				this.$emit("search", this.searchString )
			}
		},
		created(){
			this.$store.dispatch("tags/fetchAll")			
		}

	}
// </script>"