<template>
	<v-dialog :width="mwstylesettings.SmallDialogWidth">
		<template v-slot:activator="{on}">
			<v-btn class="ml-1" small v-on="on" color="igblack" dark>{{mwtr.DeleteButton}}</v-btn>	
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				{{mwtr.ConfirmDeletionMessage}}
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="deleteObject" color="igblack" dark>{{mwtr.DeleteButton}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "Delete", 
		props: {
			id: {}, 
			collection: {}
		},
		data: () => {
			return {
				deleted: false
			}
		},
		methods: {
			deleteObject(){
				this.$store.dispatch("deleteDoc", {id: this.id, collection: this.collection})
			}
		}
		// components: {

		// },
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"