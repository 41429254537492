<template>
	<span>	
		<EditButton v-bind="$props"></EditButton>
		<DeleteButton :id="id" :collection="collection"></DeleteButton>
	</span>	
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";

	import DeleteButton from "@/components/admin/buttons/DeleteButton";
	import EditButton from "@/components/admin/buttons/EditButton";

	export default {
		name: "ListButtons", 
		props: {
			id: {},
			collection: {},
			pathName: {}
		},
		components: {
			DeleteButton, 
			EditButton
		},
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"