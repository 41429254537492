<template>
	<v-dialog v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
		<template v-slot:activator="{on}">
			<v-btn v-on="on" color="primary">{{mwtr.ImportButton}}</v-btn>	
		</template>
		<v-card>

				
			<v-card-text>
				<v-tabs v-model="tab">
					<v-tab>{{mwtr.ImportButton}}</v-tab>
					<v-tab>{{mwtr.PreviewLabel}}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-textarea v-model="paste" :label="mwtr.Paste"></v-textarea>
						<v-text-field v-model="fields" :label="mwtr.Fields"></v-text-field>
					</v-tab-item>
					<v-tab-item>
						<v-data-table :items="items" :headers="headers"></v-data-table>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="dialog = false" color="igblack" dark class="mr-3">{{mwtr.CancelButton}}</v-btn>
				<v-btn @click="submit" color="primary">{{mwtr.SubmitButton}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "Import", 
		props: {
			collection: {},
			defaultFields: {}
		},
		data: () => {
			return {
				tab: 0,
				dialog: false,
				fields: [], 
				paste: "", 
				replacements: {
					"FALSE": false,
					"TRUE": true, 
				}
			}
		},
		// components: {

		// },
		computed: {
			// ...Vuex.mapState({})
			headers(){
				var fields = this.fields.split(",");
				fields = fields.map( f => {
					return {text: f, value: f.trim() }
				} );
				return fields;
			}, 
			items(){
				var self = this;
				var text = self.paste;
				if( !text.length ){
					return;
				}
				var lines = text.split("\n");
				var items = []
				lines.forEach( line => {
					var row = {}
					var cells = line.split("\t");
					self.headers.forEach( (h, i) => {
						var value = typeof self.replacements[cells[i]] !== "undefined" ? self.replacements[cells[i]] : cells[i];
						row[h.value] = value
					})
					items.push( row )
				})
				return items;
			}
		},
		methods: {
			submit(){
				const self = this;
				var addFunction = "new";
				if( this.addFunction ){
					addFunction = this.addFunction
				}
				self.items.forEach( item => {
					self.$store.dispatch(`${self.collection}/${addFunction}`, item );
				})
				self.dialog = false;
			}
		},
		created(){
			if( this.defaultFields ){
				this.fields = this.defaultFields
			}
		}

	}
// </script>"